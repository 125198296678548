"use client";

import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "./carousel.module.scss";
import Image from "next/image";
import { Col, Container, Row } from "react-bootstrap";

export interface SlideImage {
    smallSizeFileName: string;
    mediumSizeFileName: string;
    originalFileName: string;
    isActive: boolean;
    alt: string;
}

export interface Slide {
    title: string;
    description: string;
    image: SlideImage;
}

interface HompageCarouselSlider {
    slides: Slide[];
    isMobile: boolean;
}

const HomepageCarousel = ({ slides, isMobile = false }: HompageCarouselSlider) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    return (
        <div className={styles.wrapperCarousel}>
            <Carousel activeIndex={activeIndex} onSelect={handleSelect} className={styles.CarouselWrapper} touch={true} indicators={false}>
                {slides.map((slide, index) => (
                    <Carousel.Item key={index} className="slide">
                        <div className={styles.imageWrapper}>
                            <Image
                                className={styles.carouselImage}
                                src={
                                    process.env.NEXT_PUBLIC_AWS_FILES_URL +
                                    (isMobile && slide?.image?.mediumSizeFileName ? slide.image.mediumSizeFileName : slide?.image?.originalFileName)
                                }
                                alt={slide.title}
                                width={isMobile ? 500 : 1920}
                                height={isMobile ? 160 : 520}
                                placeholder="blur"
                                blurDataURL="/homeCarouselBlurImage.webp"
                                loading={index === 0 ? "eager" : "lazy"}
                            />
                        </div>
                        <Container className={styles.containerWrapper}>
                            <div className={styles.textDescription}>
                                <div dangerouslySetInnerHTML={{ __html: slide.title }} />
                                <div dangerouslySetInnerHTML={{ __html: slide.description }} />
                            </div>
                        </Container>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Container>
                <Row>
                    <Col>
                        <div className={styles.customCarouselIndicators}>
                            {slides.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSelect(index)}
                                    className={`${styles.indicatorButton} ${index === activeIndex ? styles.active : ""}`}
                                    aria-label={`Go to slide ${index + 1}`}
                                    type="button"
                                ></button>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomepageCarousel;
